import React, { useEffect, useRef } from 'react'
import {STRIPE_BILLING_DASHBOARD_PROD} from '../utils/constants'
import {sleep} from '../utils/fnUtils'

const Redirect = () => {
    let shouldRedirect = useRef(true)

    useEffect(() => {
        redirect()

        return () => {
            shouldRedirect.current = false
        }
    }, [])

    const redirect = async () => {
        await sleep(1500)

        // if this is false, it means we navigated away
        if (shouldRedirect.current) {
            window.location = STRIPE_BILLING_DASHBOARD_PROD
        }
    }

    return (
        <div className='container'>
            Redirecting you to the billing page now...
        </div>
    )
}

export default Redirect