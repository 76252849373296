import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  const navigateByElementId = (id) => {
      const element = document.getElementById(id)
      element && element.scrollIntoView()
  }

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset" styles="display:flex">
        <li>
          <button className="button button-primary button-wide-mobile button-sm" onClick={() => navigateByElementId('faq')}>Pricing</button>
        </li>
        <li>
          <a className="button button-primary button-wide-mobile button-sm" href='mailto:info@check-in-app.io?Subject=Pricing Request' target='_top'>
            Contact Us
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;