import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FAQSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Pricing',
    paragraph: ''
  };

  const [moreInfoModalActive, setMoreInfoModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setMoreInfoModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setMoreInfoModalActive(false);
  }

  return (
    <section
      {...props}
      className={outerClasses}
      id='faq'
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Pricing
                  </div>
                <h3 className="mt-0 mb-12">
                  Low setup cost, low subscription fees
                  </h3>
                <p className="m-0">
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <a
                    aria-controls="info-modal"
                    onClick={openModal}
                    href="#0"
                >
                  <stripe-pricing-table pricing-table-id="prctbl_1PXFGaAut83D0N1WJ4GA4y7n"
                                        publishable-key="pk_live_51LbFvIAut83D0N1Wol5SsY10oIGkmdHIfaby0blCxOBdjFWHrWRlibNwRp1V6wq57VGkknbO7k2NZlFbQunWs43H00cSFPXJLt">
                  </stripe-pricing-table>
                </a>
              </div>
              <Modal
                  id="info-modal"
                  show={moreInfoModalActive}
                  handleClose={closeModal}>
                  <span>More info about pricing</span>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FAQSplit.propTypes = propTypes;
FAQSplit.defaultProps = defaultProps;

export default FAQSplit;