import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
// import DemoFrame from '../components/sections/DemoFrame';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FAQSplit from '../components/sections/FAQSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      {/*<DemoFrame url="https://demo.check-in-app.io"/>*/}
      <FeaturesTiles />
      <FAQSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default Home;